import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { add } from "date-fns";

import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  groupByValue,
  lineColors as lineColor,
  lineColors,
} from "../../../utils";

import Panel from "../../../components/panels/Panel";
import Map from "../../../components/map/Map";
import TimeseriesFilters from "../../../components/filters/TimeseriesFilters";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import Table from "../../../components/Table";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";
import { Select } from "@lrewater/lre-react";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import PrintGraphButton from "../../../components/graphs/PrintGraphButton";

const BoldSelect = styled(Select)`
  & .MuiInputBase-root {
    font-weight: 900;
  }
`;
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100% - 92px);
  width: 100%;
`;
const FiltersContainer = styled.div`
  height: 100%;
  width: 100%;
`;
const MapContainer = styled.div`
  height: 300px;
  width: 100%;
`;
const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const WaterDepthVsPumping = () => {
  const { getAccessTokenSilently } = useAuth0();
  const saveRef = useRef(null);

  const [graphAccordionExpanded, setGraphAccordionExpanded] = useState(true);

  //date filter defaults
  const defaultFilterValues = {
    previousDays: 365,
    startDate: null,
    endDate: new Date(),
    checked: true,
    yL: "Groundwater Depth",
    yR: "Pumping Rate",
  };
  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const changeFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  //columns and fields to render on table
  const tableColumns = [
    { title: "Well Name", field: "well_name", width: "100%" },
    { title: "Parameter", field: "parameter", width: "100%" },
    { title: "Units", field: "units" },
    {
      title: "Date",
      field: "d_date",
      // render: (rowData) => {
      //   return dateFormatter(rowData.d_date, "MM/DD/YYYY, h:mm A");
      // },
      type: "date",
    },
    { title: "Measured Value", field: "measured_value" },
    { title: "Static Water Depth", field: "static_water_depth" },
  ];

  const locationsOptions = [
    { name: "Well 4A", ndx: 21 },
    { name: "Well 9A", ndx: 33 },
    { name: "Well 13D", ndx: 26 },
    { name: "Well 12A", ndx: 29 },
    { name: "Well 11D", ndx: 28 },
    { name: "Well 8A", ndx: 32 },
    { name: "Well 7D", ndx: 24 },
    { name: "Well 3A", ndx: 25 },
    { name: "Well 14A", ndx: 30 },
    { name: "Well 2D", ndx: 27 },
    { name: "Well 2A", ndx: 23 },
    { name: "Well 3D", ndx: 22 },
    { name: "Well 1A", ndx: 31 },
  ];

  //locations in picker that are selected by user
  const [selectedLocation, setSelectedLocation] = useState(
    locationsOptions[0].ndx
  );

  const handleFilter = (event) => {
    setSelectedLocation(event.target.value);
  };

  const [graphData, setGraphData] = useState();
  const { isFetching, error, data } = useQuery(
    ["timeseries-final-waterlevel-v-gpm", selectedLocation],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/timeseries-final-waterlevel-v-gpm/${selectedLocation}`,
          { headers }
        );
        const groupedDataArray = groupByValue(data, "parameter");
        const groupedDataObj = {};
        groupedDataArray.forEach(
          (item) => (groupedDataObj[item[0].parameter] = item)
        );
        setGraphData(groupedDataObj);

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: false, refetchOnWindowFocus: false }
  );

  //filtered data for graph, it filters selected locations
  //it keeps every date so the graph can still be panned and zoomed
  //it also mutates the data to be consumed by chartsJS
  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState([]);
  //filtered data for table. if filters selected locations
  //it also filters by date
  const [filteredTableData, setFilteredTableData] = useState([]);
  useEffect(() => {
    if (data && graphData) {
      const tableFilterData =
        //if there is no value in the input, yield every record
        filterValues.previousDays === ""
          ? data
          : //if the toggle is checked, yield x days
          filterValues.checked
          ? data.filter(
              (item) =>
                new Date(item.d_date) >=
                  add(new Date().getTime(), {
                    days: -filterValues.previousDays,
                  }) && new Date(item.d_date) <= new Date()
            )
          : //if the toggle is unchecked, yield date range
            data.filter(
              (item) =>
                new Date(item.d_date) >= filterValues.startDate &&
                new Date(item.d_date) <= filterValues.endDate
            );
      setFilteredTableData(tableFilterData);

      //mutate data for chartJS to use
      const defaultStyle = {
        pointStyle: "point",
        pointRadius: 0,
        pointHoverRadius: 4,
      };
      const mutatedGraphData = {
        labels: graphData[filterValues["yL"]].map((item) => item.d_date),
        datasets: [
          // Pumping Rate (unchanged)
          {
            label: filterValues["yR"],
            units: graphData[filterValues["yR"]]
              ? graphData[filterValues["yR"]][0].units
              : null,
            yAxisID: "yR",
            borderColor: lineColor.darkGray,
            backgroundColor: lineColor.darkGray,
            data: graphData[filterValues["yR"]]?.map(
              (item) => item.measured_value
            ),
            borderWidth: 2,
            ...defaultStyle,
          },
          // Static Water Depth (color changed to a slightly darker blue, plus dashed + thicker)
          {
            label: "Static Water Depth",
            units: graphData[filterValues["yL"]]
              ? graphData[filterValues["yL"]][0].units
              : null,
            yAxisID: "yL",
            fill: false,
            // just a bit darker than the existing lightBlue
            borderColor: "#1E88E5",
            backgroundColor: "#1E88E5",
            data: graphData[filterValues["yL"]]?.map(
              (item) => item.static_water_depth
            ),
            borderWidth: 7,
            borderDash: [8, 8], // dashed so it doesn't hide underneath Permitted Pumping Max
            ...defaultStyle,
          },
          // === NEW DATASET: Permitted Pumping Max ===
          {
            label: "Permitted Pumping Max",
            units: graphData[filterValues["yR"]]
              ? graphData[filterValues["yR"]][0].units
              : null,
            yAxisID: "yR", // same axis as Pumping Rate
            borderColor: lineColors.maroon, // re-use Static Water Depth's old color
            backgroundColor: lineColors.maroon, // re-use Static Water Depth's old color
            data: graphData[filterValues["yR"]]?.map(
              (item) => item.max_pumping_rate
            ),
            borderWidth: 4,
            ...defaultStyle,
          },

          // Groundwater Depth (unchanged)
          {
            label: filterValues["yL"],
            units: graphData[filterValues["yL"]]
              ? graphData[filterValues["yL"]][0].units
              : null,
            yAxisID: "yL",
            fill: true,
            borderColor: lineColors.lightBlue,
            backgroundColor: lineColors.lightBlue + "4D",
            data: graphData[filterValues["yL"]]?.map(
              (item) => item.measured_value
            ),
            borderWidth: 3,
            ...defaultStyle,
          },
        ],
      };
      setFilteredMutatedGraphData(mutatedGraphData);
    }
  }, [data, graphData, filterValues, selectedLocation]);

  return (
    <>
      <Helmet title="Water Depth vs Pumping" />
      <Typography variant="h3" gutterBottom display="inline">
        Water Depth vs Pumping
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Water Depth vs Pumping</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={7}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <Map
                  locationsToInclude={locationsOptions.map(
                    (location) => location.name
                  )}
                  startingLocation={[-104.81527805328369, 39.05620077626861]}
                  startingZoom={11.5}
                />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="date-filters"
              id="date-filters"
            >
              <Typography variant="h4" ml={2}>
                Date Filters
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <FiltersContainer>
                  <TimeseriesFilters
                    filterValues={filterValues}
                    changeFilterValues={changeFilterValues}
                  />
                </FiltersContainer>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            onChange={(e, expanded) => setGraphAccordionExpanded(expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                {!graphAccordionExpanded && "Graph"}
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TimeseriesContainer>
                  <Grid container pb={6} mt={2}>
                    <Grid
                      item
                      style={{ flexGrow: 1, maxWidth: "calc(100% - 54px)" }}
                    >
                      <BoldSelect
                        name="locations"
                        label="Locations"
                        variant="outlined"
                        outlineColor="primary"
                        labelColor="primary"
                        valueField="ndx"
                        displayField="name"
                        data={locationsOptions}
                        value={selectedLocation}
                        onChange={handleFilter}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        width: "106px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SaveGraphButton
                        ref={saveRef}
                        title="Water Depth vs Pumping Timeseries Graph"
                      />
                      <PrintGraphButton
                        ref={saveRef}
                        title="Water Depth vs Pumping Timeseries Graph"
                      />
                    </Grid>
                  </Grid>

                  <TableWrapper>
                    <TimeseriesLineChart
                      reverseLegend={false}
                      data={filteredMutatedGraphData}
                      error={error}
                      isLoading={isFetching}
                      filterValues={filterValues}
                      locationsOptions={locationsOptions}
                      yLLabel={
                        graphData &&
                        graphData[filterValues["yL"]] &&
                        `${graphData[filterValues["yL"]][0]?.parameter} (${
                          graphData[filterValues["yL"]][0]?.units
                        } above pump)`
                      }
                      yRLLabel={
                        graphData &&
                        graphData[filterValues["yR"]] &&
                        `${graphData[filterValues["yR"]][0]?.parameter} (${
                          graphData[filterValues["yR"]][0]?.units
                        })`
                      }
                      title={`Water Depth vs Pumping for ${
                        locationsOptions.find(
                          (location) => location.ndx === selectedLocation
                        ).name
                      }`}
                      ref={saveRef}
                      // minL={1400}
                      // maxL={1700}
                      // minR={0}
                      // maxR={650}
                      tooltipFormat="MM-DD-YYYY"
                    />
                  </TableWrapper>
                </TimeseriesContainer>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Table
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    // isLoading={isLoading}
                    label="Water Depth vs Pumping Timeseries Table"
                    columns={tableColumns}
                    data={filteredTableData}
                    height="600px"
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default WaterDepthVsPumping;
